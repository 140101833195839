import React from 'react';

import AppCrashFallback from './components/AppCrashFallback';
import ErrorBoundary from './components/ErrorBoundary';
import Root from './components/Root';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import Auth0ProviderWithHistory from 'components/Auth0ProviderWithHistory';

import { createGlobalStyle } from 'styled-components';
import { colorUsage } from 'stylesheet';

const GlobalStyle = createGlobalStyle`
  @media print {
    .MuiModal-root{

    overflow: auto;
    height: fit-content;
    position: absolute !important;

    };
  }

  body {
    background-color: ${colorUsage.contentBackground};
  }
`;

const App: React.FunctionComponent = () => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <ErrorBoundary FallbackComponent={AppCrashFallback}>
    <BrowserRouter>
      <GlobalStyle />
      <Auth0ProviderWithHistory>
        <Root>
          <Routes />
        </Root>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </ErrorBoundary>
);

export default App;
