import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

if (process.env.REACT_APP_SENTRY_DSN !== null) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 0.1,
  });
}

const rootEl = document.getElementById('root');

if (rootEl) {
  ReactDOM.render(<App />, rootEl);
}

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default; // eslint-disable-line
    if (rootEl) {
      ReactDOM.render(<NextApp />, rootEl);
    }
  });
}
