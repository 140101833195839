import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loader from './components/Loader/Loader';

const Home = lazy(() => import('./pages/Home'));
const Project = lazy(() => import('./pages/Project'));
const Manager = lazy(() => import('./pages/Manager'));
const Admin = lazy(() => import('./pages/Admin'));
const StatsSatisfactions = lazy(() => import('./pages/StatsSatisfaction'));
const StatsDefects = lazy(() => import('./pages/StatsDefects'));
const Defects = lazy(() => import('./pages/Defects'));
const LoginCallback = lazy(() => import('./pages/LoginCallback'));
const Satisfaction = lazy(() => import('./pages/Satisfaction'));

export const PATHS = {
  HOME: '/',
  PROJECT: '/project/:startup',
  SATISFACTION: '/satisfaction/:startup',
  MANAGER: '/manager',
  STATS_SATISFACTION: '/stats/satisfaction/:startup',
  DEFECTS: '/defects/:startup',
  STATS_DEFECTS: '/stats/defects/:startup',
  ADMIN: '/admin',
  LOGIN_CALLBACK: '/callback',
  WITH_TOKEN_SATISFACTION: '/with-token/satisfaction/:startup',
};

const routes = (): JSX.Element => (
  <Suspense fallback={<Loader />}>
    <Switch>
      <Route exact path={PATHS.LOGIN_CALLBACK} component={LoginCallback} />
      <Route exact path={PATHS.HOME} component={withAuthenticationRequired(Home)} />
      <Route path={PATHS.PROJECT} component={withAuthenticationRequired(Project)} />
      <Route path={PATHS.SATISFACTION} component={withAuthenticationRequired(Satisfaction)} />
      <Route path={PATHS.MANAGER} component={withAuthenticationRequired(Manager)} />
      <Route path={PATHS.STATS_DEFECTS} component={withAuthenticationRequired(StatsDefects)} />
      <Route path={PATHS.DEFECTS} component={withAuthenticationRequired(Defects)} />
      <Route
        path={PATHS.STATS_SATISFACTION}
        component={withAuthenticationRequired(StatsSatisfactions)}
      />
      <Route path={PATHS.ADMIN} component={withAuthenticationRequired(Admin)} />
      <Route exact path={PATHS.WITH_TOKEN_SATISFACTION} component={Satisfaction} />
    </Switch>
  </Suspense>
);

export default routes;
